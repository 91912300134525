'use client';

import { useCallback, useEffect, useMemo, useSyncExternalStore } from 'react';

import { SubscriptionRegistry } from '@/api/useSubscription/registry';

export type UseWsReturn<T> = { data: T | undefined; error: boolean; loading: boolean };
export type UseWsConfig<T> = {
  /**
   * Used to correctly match existing entry data with entry updates,
   * to do this we need to identify both entries and match them.
   *
   * Problem: for some entities, there is no ready-made reliable identifier out of the box,
   * and this function is used to get reliable identifier for each entry and helps match them
   *
   * @example for recap assets: assetId = asset.name + asset.platform
   * { getEntryKey: (entry) => entry.name + entry.platform }
   */
  getEntryKey?: T extends (infer U)[] ? (entry: U) => string : never;
};

const subscriptionRegistryInstance = SubscriptionRegistry.getInstance();

export function useSubscription<T>(
  action: string,
  body: Record<string, any> = {},
  config?: UseWsConfig<T>,
): UseWsReturn<T> {
  const subscriptionKey = useMemo(
    () => subscriptionRegistryInstance.getSubscriptionKey(action, body),
    [action],
  );

  useEffect(() => {
    subscriptionRegistryInstance.subscribe(action, body, config);
    return () => subscriptionRegistryInstance.unsubscribe(subscriptionKey);
  }, [subscriptionKey]);

  const subscribeExternalStore = useCallback(
    (onStoreChange: () => void) => subscriptionRegistryInstance.addListener(subscriptionKey, onStoreChange),
    [subscriptionKey],
  );

  const getSnapshotExternalStore = useCallback(
    () => subscriptionRegistryInstance.getSubscription(subscriptionKey),
    [subscriptionKey],
  );

  const subscription = useSyncExternalStore(
    subscribeExternalStore,
    getSnapshotExternalStore,
    getSnapshotExternalStore,
  );

  return {
    data: subscription?.data,
    error: subscription?.error,
    loading: subscription?.loading,
  };
}
