export enum SubscriptionEventType {
  'EVENT' = 'EVENT',
  'RESPONSE' = 'RESPONSE',
  'SUBSCRIPTION' = 'SUBSCRIPTION',
  'UNSUBSCRIPTION' = 'UNSUBSCRIPTION',
  'REQUEST' = 'REQUEST',
}

export type SubscriptionResponseEvent = {
  type: SubscriptionEventType.RESPONSE;
  action: string;
  rid: number;
  body: { data: any };
  sid: number;
};
export type SubscriptionErrorEvent = {
  type: SubscriptionEventType.RESPONSE;
  action: string;
  rid: number;
  error: any;
};
export type SubscriptionEvent = {
  type: SubscriptionEventType.EVENT;
  action: string;
  aid: number;
  body: { data: any };
  sid: number;
};
export type SubscriptionMessage = SubscriptionEvent | SubscriptionErrorEvent | SubscriptionResponseEvent;

export interface Subscription {
  key: string;
  action: string;
  rid?: number;
  sid?: number;
  lastAid: number;
  data?: any;
  error: boolean;
  loading: boolean;
  body: Record<string, any>;
  config?: { getEntryKey?: (item: any) => string };
  listeners: Set<() => void>;
  subscribersCount: number;
  unsubscribeOnFirstEvent: boolean;
  unsubscribeTimeout?: ReturnType<typeof setTimeout>;
  onSocketOpen?: (e: Event) => void;
  notifyListeners: () => void;
  remainingRetries: number;
}

export enum SocketCloseCode {
  NORMAL_CLOSURE = 1000,
  SOME_ERROR = 4000,
  SESSION_CLOSED = 4002,
  TIMEOUT = 4003,
  MAINTENANCE_MODE_ON = 4005,
  TOO_MANY_REQUESTS = 4029,
}
