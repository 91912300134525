/** Used to merge existing data with updates (partialEvents) */
export function merge<T extends any[]>(
  data: T,
  updates: Partial<T[number]>[],
  getEntryKey: (entry: T[number]) => string,
): T {
  const lookup = new Map<string, T[number]>();

  for (let i = 0; i < updates.length; i++) {
    const item = updates[i];
    lookup.set(getEntryKey(item), item);
  }

  if (!lookup.size) {
    return data;
  }

  const merged = data.map<T[number]>((el) => ({
    ...el,
    ...lookup.get(getEntryKey(el)),
  }));

  // @ts-ignore
  return merged;
}

export function debug(...args: any) {
  if (process.env.NODE_ENV !== 'development') {
    return;
  }
  console.debug(
    `[Subscription]`,
    ...args.map((a: any) => (typeof a === 'object' ? JSON.parse(JSON.stringify(a)) : a)),
  );
}

export function warn(...args: any) {
  console.warn(
    `[Subscription]`,
    ...args.map((a: any) => (typeof a === 'object' ? JSON.parse(JSON.stringify(a)) : a)),
  );
}

export function logError(...args: any) {
  console.error(
    `[Subscription]`,
    ...args.map((a: any) => (typeof a === 'object' ? JSON.parse(JSON.stringify(a)) : a)),
  );
}
