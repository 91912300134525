'use client';

import { RecapAsset } from '@/api/trading/types';
import { mapAssetPlatform } from '@/api/trading/utils';
import { ApiPlatform } from '@/api/types';
import { useSubscription } from '@/api/useSubscription';

const getEntryKey = (entry: RecapAsset) => entry.name + entry.platform;

export const useRecap = (
  platform?: ApiPlatform,
  initialData: RecapAsset[] | null = [],
): { markets: RecapAsset[] | null; error: boolean; loading: boolean } => {
  const { data = initialData, error } = useSubscription<RecapAsset[]>(
    'recap',
    {
      partialEvents: true,
      // platform, // not working
    },
    { getEntryKey },
  );

  return {
    markets: !data
      ? null
      : platform
        ? data.map(mapAssetPlatform).filter((asset) => asset.platform === platform)
        : data.map(mapAssetPlatform),
    loading: !error && !data?.length,
    error,
  };
};
