import {
  SocketCloseCode,
  Subscription,
  SubscriptionErrorEvent,
  SubscriptionEvent,
  SubscriptionEventType,
  SubscriptionMessage,
  SubscriptionResponseEvent,
} from '@/api/useSubscription/types';

import { getClientApiDomain } from '../fetchers/fetchClient';
import { debug, logError, merge, warn } from './utils';

/**
 * @example
 *  const subscriptionKey = useMemo(() => subscriptionRegistryInstance.getSubscriptionKey(action, body), [action]);
 *
 *  useEffect(() => {
 *    subscriptionRegistryInstance.subscribe(action, body, config);
 *    return () => subscriptionRegistryInstance.unsubscribe(subscriptionKey);
 *  }, [subscriptionKey]);
 *
 *  const subscribeExternalStore = useCallback((onStoreChange: () => void) => subscriptionRegistryInstance.addListener(subscriptionKey, onStoreChange), [subscriptionKey]);
 *
 *  const getSnapshotExternalStore = useCallback(() => subscriptionRegistryInstance.getSubscription(subscriptionKey),[subscriptionKey],);
 *
 *  const subscription = useSyncExternalStore(subscribeExternalStore,getSnapshotExternalStore,getSnapshotExternalStore);
 *
 *  return ... subscription.data etc
 */
export class SubscriptionRegistry {
  private static instance: SubscriptionRegistry;
  public readonly UNSUBSCRIBE_TIMEOUT = process.env.NODE_ENV === 'development' ? 5_000 : 10_000;
  public readonly SOCKET_CLOSE_NO_ACTIVE_SUBSCRIPTIONS_TIMEOUT =
    process.env.NODE_ENV === 'development' ? 5_000 : 10_000;

  private readonly MAX_RETRY = 5;
  private readonly RETRY_BASE_TIMEOUT = 1000;
  private readonly RETRY_BACKOFF_FACTOR = 2;

  private subscriptions: Record<string, Subscription> = {};
  private socket: WebSocket | null = null;
  private socketCloseTimer: ReturnType<typeof setTimeout> | null = null;
  private lastRid = 0;

  private constructor() {}

  public static getInstance() {
    if (!SubscriptionRegistry.instance) {
      SubscriptionRegistry.instance = new SubscriptionRegistry();
    }
    return SubscriptionRegistry.instance;
  }

  public subscribe(action: string, body: Record<string, any>, config?: any) {
    const socket = this.getSocket();
    const subscriptionKey = this.getSubscriptionKey(action, body);
    let subscription = this.getSubscription(subscriptionKey);

    if (!subscription) {
      subscription = this.createSubscriptionEntry(body, action, config);
      subscription.subscribersCount = 1;
      this.addSubscription(subscription);

      const sendSubscribe = () => {
        debug('Send SUBSCRIPTION', subscription);
        socket.send(
          JSON.stringify({
            action,
            type: SubscriptionEventType.SUBSCRIPTION,
            rid: subscription.rid,
            body,
          }),
        );
      };

      if (socket.readyState === WebSocket.OPEN) {
        sendSubscribe();
      } else {
        subscription.onSocketOpen = sendSubscribe;
        debug('Socket is not opened yet, waiting for open to send SUBSCRIPTION', subscription);
      }

      return;
    }

    if (subscription.unsubscribeOnFirstEvent) {
      debug('Subscription already exists, cancel unsubscribe on first event', subscription);
      subscription.unsubscribeOnFirstEvent = false;
    }

    if (subscription.unsubscribeTimeout) {
      debug('Subscription already exists, cancel unsubscribe by timeout', subscription);
      clearTimeout(subscription.unsubscribeTimeout);
      subscription.unsubscribeTimeout = undefined;
    }

    subscription.subscribersCount++;
  }

  public unsubscribe(subscriptionKey: string): void {
    const socket = this.getSocket();
    const subscription = this.getSubscription(subscriptionKey);

    if (!subscription) {
      logError('No subscription entry found for UNSUBSCRIPTION', subscriptionKey);
      return;
    }

    subscription.subscribersCount--;

    if (subscription.subscribersCount > 0) {
      debug('Not unsubscribing because active listeners', subscription);
      return;
    }

    if (subscription.onSocketOpen) {
      subscription.onSocketOpen = undefined;
    }

    if (socket.readyState !== WebSocket.OPEN) {
      debug("Socket wasn't opened yet, just removing subscription", subscription);
      return this.removeSubscription(subscription);
    }

    if (typeof subscription.sid !== 'number') {
      warn(`Unable to unsubscribe, waiting for first sid`, subscription);
      subscription.unsubscribeOnFirstEvent = true;
      return;
    }

    debug('Started UNSUBSCRIPTION timeout', subscription);

    subscription.unsubscribeTimeout = setTimeout(() => {
      debug(`Sending UNSUBSCRIPTION`, subscription);
      socket.send(
        JSON.stringify({
          action: subscription.action,
          type: SubscriptionEventType.UNSUBSCRIPTION,
          rid: subscription.rid,
          body: {
            sid: subscription.sid,
          },
        }),
      );
      this.removeSubscription(subscription);
    }, this.UNSUBSCRIBE_TIMEOUT);
  }

  public getSubscriptionKey(action: string, body: any): string {
    return action + JSON.stringify(Object.entries(body).sort(([key1], [key2]) => key1.localeCompare(key2)));
  }

  public addListener(subscriptionKey: string, onStoreChange: () => void) {
    this.getSubscription(subscriptionKey)?.listeners.add(onStoreChange);
    return () => this.getSubscription(subscriptionKey)?.listeners.delete(onStoreChange);
  }

  public getSubscription(subscriptionKey: string): Subscription {
    return this.subscriptions[subscriptionKey];
  }

  public clearSocketCloseTimer() {
    if (this.socketCloseTimer) {
      clearTimeout(this.socketCloseTimer);
    }
    this.socketCloseTimer = null;
  }

  private addSubscription(subscription: Subscription) {
    this.subscriptions[subscription.key] = subscription;
    if (this.socketCloseTimer) {
      this.clearSocketCloseTimer();
      debug('New subscription appear, cancel socket closing', subscription);
    }
  }

  private onMessage = (event: MessageEvent<string>) => {
    const message: SubscriptionMessage = JSON.parse(event.data);
    const subscription = this.getSubscriptionByMessage(message);

    if (!subscription) {
      return warn(`No subscription key found for event`, message);
    }

    if (this.isErrorMessage(message)) {
      return this.onErrorMessage(message, subscription);
    }

    if (message.type === SubscriptionEventType.RESPONSE) {
      return this.onResponseMessage(message, subscription);
    }

    if (message.type === SubscriptionEventType.EVENT) {
      return this.onEventMessage(message, subscription);
    }
  };

  private removeSubscription(subscription: Subscription): void {
    delete this.subscriptions[subscription.key];
    if (this.getSubscriptionsCount() > 0) {
      return;
    }
    debug('No active subscriptions, started socket close timer');
    this.socketCloseTimer = setTimeout(() => {
      if (this.getSubscriptionsCount() === 0 && this.socket) {
        warn('Close socket because no active subscriptions');
        this.destroySocket();
        this.clearSocketCloseTimer();
      }
    }, this.SOCKET_CLOSE_NO_ACTIVE_SUBSCRIPTIONS_TIMEOUT);
  }

  private updateSubscription(subscription: Subscription, data: Partial<Subscription>): void {
    this.subscriptions[subscription.key] = {
      ...this.subscriptions[subscription.key],
      ...data,
    };
    this.subscriptions[subscription.key].notifyListeners();
  }

  private onErrorMessage(response: SubscriptionErrorEvent, subscription: Subscription): void {
    this.updateSubscription(subscription, { error: true, loading: false });
    logError(response);

    if (response.error.code !== 'SYSTEM_ERROR') {
      return;
    }

    if (subscription.remainingRetries !== this.MAX_RETRY) {
      debug('Retrying already started', subscription);
      return;
    }

    const retry = () => {
      const s = this.getSubscription(subscription.key);
      if (!s.error) {
        debug(`Success after ${this.MAX_RETRY - s.remainingRetries} retries`, s);
        return;
      }
      if (s.remainingRetries <= 0) {
        logError('Retries finished', s);
        this.removeSubscription(s);
        return;
      }
      s.remainingRetries--;
      debug('Send SUBSCRIPTION', s);
      this.getSocket().send(
        JSON.stringify({
          type: SubscriptionEventType.SUBSCRIPTION,
          action: s.action,
          rid: s.rid,
          body: s.body,
        }),
      );
      const delay =
        this.RETRY_BASE_TIMEOUT * Math.pow(this.RETRY_BACKOFF_FACTOR, this.MAX_RETRY - s.remainingRetries);
      setTimeout(() => retry(), delay);
    };

    retry();
  }

  private onResponseMessage(response: SubscriptionResponseEvent, subscription: Subscription): void {
    this.updateSubscription(subscription, {
      sid: response.sid,
      data: response.body?.data,
      loading: false,
      error: false,
    });
  }

  private onEventMessage(response: SubscriptionEvent, subscription: Subscription): void {
    if (response.aid <= subscription.lastAid) {
      return warn('EVENT with wrong aid received', response);
    }

    if (subscription.unsubscribeOnFirstEvent) {
      debug(`first sid received, sending UNSUBSCRIPTION`, subscription);
      this.getSocket().send(
        JSON.stringify({
          action: response.action,
          type: SubscriptionEventType.UNSUBSCRIPTION,
          rid: subscription.rid,
          body: {
            sid: subscription.sid,
          },
        }),
      );
      this.removeSubscription(subscription);
      return;
    }

    const incomingData = response.body?.data;

    if (!subscription.body.partialEvents) {
      this.updateSubscription(subscription, {
        data: incomingData,
        lastAid: response.aid,
        error: false,
      });
    } else {
      if (typeof subscription?.config?.getEntryKey !== 'function') {
        throw new Error('config.getEntryKey required when body.partialEvents=true');
      }
      this.updateSubscription(subscription, {
        lastAid: response.aid,
        error: false,
        data: merge<any>(
          subscription.data,
          incomingData,
          // @ts-ignore
          subscription.config?.getEntryKey,
        ),
      });
    }
  }

  private createSubscriptionEntry(
    body: Record<string, any> = {},
    action: string,
    config?: { getEntryKey?: (item: any) => string },
  ): Subscription {
    return {
      key: this.getSubscriptionKey(action, body),
      config,
      body,
      action,
      rid: this.lastRid++,
      lastAid: 0,
      sid: undefined,
      data: undefined,
      error: false,
      loading: true,
      listeners: new Set(),
      subscribersCount: 0,
      unsubscribeOnFirstEvent: false,
      remainingRetries: this.MAX_RETRY,
      notifyListeners() {
        this.listeners.forEach((listener) => listener());
      },
    };
  }

  private isErrorMessage(message: SubscriptionMessage): message is SubscriptionErrorEvent {
    return 'error' in message && message.error;
  }

  private getSubscriptionsCount(): number {
    return Object.keys(this.subscriptions).length;
  }

  private getSubscriptionByMessage(message: SubscriptionMessage): Subscription | undefined {
    return Object.entries(this.subscriptions).find(([k, v]) =>
      message.type === SubscriptionEventType.RESPONSE && message.rid != null
        ? v.rid === message.rid
        : message.type === SubscriptionEventType.EVENT && message.sid != null
          ? v.sid === message.sid
          : false,
    )?.[1];
  }

  private onSocketOpen = (e: Event) => {
    Object.values(this.subscriptions).forEach((subscription) => {
      if (subscription.onSocketOpen) {
        debug('Socket was opened, managing queue', subscription);
        subscription.onSocketOpen(e);
        subscription.onSocketOpen = undefined;
      }
    });
  };

  private onSocketClose(e: CloseEvent) {
    if (e.code === SocketCloseCode.NORMAL_CLOSURE) {
      return debug('Socket closed normally');
    } else if (e.code in SocketCloseCode) {
      logError('Socket closed abnormally with known code', SocketCloseCode[e.code], e);
    } else {
      logError('Socket closed abnormally with unknown code', e.code, e);
    }
  }

  private destroySocket() {
    if (!this.socket) {
      return warn('Unable to destroy socket, it doesnt exist');
    }
    this.socket.close(SocketCloseCode.NORMAL_CLOSURE);
    this.socket.removeEventListener('message', this.onMessage);
    this.socket.removeEventListener('close', this.onSocketClose);
    this.socket.removeEventListener('open', this.onSocketOpen);
    this.socket = null;
  }

  private getSocket(): WebSocket {
    if (!this.socket) {
      // this.socket = new WebSocket(`ws://localhost:8080`);
      this.socket = new WebSocket(`wss://${getClientApiDomain()}/v2/ws/`);
      this.socket.addEventListener('open', this.onSocketOpen);
      this.socket.addEventListener('message', this.onMessage);
      this.socket.addEventListener('close', this.onSocketClose);
    }
    return this.socket;
  }
}
